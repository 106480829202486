(function($) {
  console.log('koko',$('#pc-head'))
  $('.menu-btn').on('click', function(){
    const nav = $('.s-menu nav')
    if(nav.hasClass('show')){
      nav.removeClass('show')
      $('.hamburger').removeClass('is-active')
    }else{
      nav.addClass('show')
      $('.hamburger').addClass('is-active')
    }
  })

  $('.fancy').fancybox({
  });

  imagesLoaded($('.grid-index'), function () {
    $('.grid-index').masonry({
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true
    });
  });

})(jQuery);
